<template>
  <section>
    <b-card>
      <div class="row mb-4">
        <div class="col-12 d-flex">
          <span class="h2 mr-auto text-secondary"> Nouveau principe</span>

          <div class="">
            <modal-actions @close="$router.go(-1)" />
          </div>
        </div>
      </div>

      <div class="shdow">
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <span class="h3 text-secondary">Informations générales</span>
          <div>
            <b-button @click="createItem" type="submit" pill :disabled="submitingForm">
              <b-spinner
                small
                v-if="submitingForm"
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
          </div>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />

        <!-- inputs -->
        <div class="row">
          <!-- First Col -->

          <div class="col-12 col-md-6">
            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Libellé:</label>
              </div>
              <div class="col-md-8">
                <b-form-input
                  type="text"
                  placeholder="Libellé"
                  :class="{
                    'is-invalid':
                      $v.newPrincipe.name.$error && $v.newPrincipe.name.$dirty,
                  }"
                  v-model="newPrincipe.name"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newPrincipe.name.$dirty"
                >
                  {{
                    !$v.newPrincipe.name.required
                      ? "Champ obligatoire"
                      : !$v.newPrincipe.name.minLength
                      ? `Le champ doit contenir au moins ${$v.newPrincipe.name.$params.minLength.min} caractères.`
                      : ""
                  }}
                </span>
                <!--  -->
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Code:</label>
              </div>
              <div class="col-md-8">
                <b-form-input
                  type="text"
                  placeholder="Code"
                  :class="{
                    'is-invalid':
                      $v.newPrincipe.code.$error && $v.newPrincipe.code.$dirty,
                  }"
                  v-model="newPrincipe.code"
                ></b-form-input>
                <!-- ERROR MESSAGES -->
                <span
                  style="font-size: x-small"
                  class="text-danger font-weight-normal"
                  v-if="$v.newPrincipe.code.$dirty"
                >
                  {{ !$v.newPrincipe.code.required ? "Champ obligatoire" : "" }}
                </span>
                <!--  -->
              </div>
            </div>

            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Date de création:</label>
              </div>
              <div class="col-md-8">
                <date-picker
                  type="text"
                  placeholder="Date de création"
                  class="w-100"
                  :value="new Date()"
                  format="DD/MM/YYYY"
                  disabled
                ></date-picker>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="row my-3 align-items-center">
              <div class="col-md-4">
                <label class="font-weight-bold">Description:</label>
              </div>
              <div class="col-md-8">
                <b-form-textarea
                  type="text"
                  placeholder="Description"
                  v-model="newPrincipe.description"
                ></b-form-textarea>
              </div>
            </div>
          </div>
        </div>
        <!-- end inputs -->
      </div>

      <!-- Tabs -->
      <b-tabs class="tab-solid tab-solid-primary mt-5">
        <b-tab title="Cadres rattachés">
          <div class="d-flex">
            <span class="h4 text-secondary mr-auto my-auto">Liste des cadres </span>

            <div class="d-flex justify-content-end">
              <custom-add-button text="Ajouter un cadre" disabled></custom-add-button>
            </div>
          </div>
          <div class="col-12 p-0">
            <hr class="mt-1 mb-3 bg-secondary" />
          </div>
          <Cadres :fields="cadre.fields" :items="[]" />
        </b-tab>
        <b-tab v-if="false"  title="Implémentation">
          <Implementation disabled :fields="impl.fields" :items="[]" />
        </b-tab>
        <b-tab v-if="false"  title="Couverture project">
          <Implementation disabled :fields="impl.fields" :items="[]" />
        </b-tab>
      </b-tabs>

      <!-- End Tabs -->
    </b-card>

    <!-- Add Cadre Modal -->
    <div to="modals-xyz-548" v-if="showAddCadre">
      <modal @close="showAddCadre = !showAddCadre">
        <add-cadre />
      </modal>
    </div>

    <!-- Add Object Modal -->
    <div to="modals-xyz-548" v-if="showAddObject">
      <modal @close="showAddObject = !showAddObject">
        <add-object />
      </modal>
    </div>
  </section>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import Cadres from "./components/Cadres.vue";
import Implementation from "./components/Implementations.vue";
import Modal from "@/components/custom/Modal.vue";
import AddCadre from "./components/AddCadre.vue";
import AddObject from "./components/AddObject.vue";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import CustomAddButton from "../../../components/custom/CustomAddButton";

export default {
  components: {
    CustomAddButton,
    ModalActions,
    Cadres,
    Implementation,
    Modal,
    AddCadre,
    AddObject,
  },
  data: () => ({
    showAddCadre: false,
    showAddObject: false,
    submitingForm: false,
    cadre: {
      items: [
        {
          type: "Regles",
          code: 56,
          libelle: "Api station",
          couverture: 30,
          status: "Production",
          capacite: "Services d'echanges",
          implementaion: 4,
          livrable: 2,
          // actions: ["edit", "delete", "show"],
          actions: ["show"],
        },
      ],
      fields: [
        { key: "type", label: "Type cadre", sortable: true },
        { key: "code", label: "Code", sortable: true },
        { key: "libelle", label: "Libellé", sortable: true },
        { key: "couverture", label: "Couverture projet", sortable: true },
        { key: "capacite", label: "Capacite technique", sortable: true },
        { key: "implementaion", label: "Implementaion", sortable: true },
        { key: "status", label: "Statut", sortable: true },
        { key: "livrable", label: "Livrables", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    },
    impl: {
      items: [
        {
          type: "Composant Technique",
          libelle: "OVH",
          po: "O. GUISSE",
          capacite: "Cloud",
          patrimoine: 4,
          // actions: ["edit", "delete", "show"],
          actions: ["delete"],
        },
      ],
      fields: [
        { key: "type", label: "Type object", sortable: true },
        { key: "libelle", label: "Libellé object", sortable: true },
        { key: "po", label: "PO", sortable: true },
        { key: "capacite", label: "Capacité", sortable: true },
        { key: "patrimoine", label: "Patrimoine", sortable: true },
        {
          key: "actions",
          label: "Actions",
          thStyle: { width: "150px !important", minWidth: "150px !important" },

          sortable: false,
        },
      ],
    },
    newPrincipe: {
      name: "",
      description: "",
      code: "",
    },
  }),
  validations: {
    newPrincipe: {
      name: {
        required,
        minLength: minLength(5),
      },
      code: {
        required,
      },
    },
  },
  methods: {
    createItem() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.submitingForm = true;
        this.$store.dispatch("principe/createPrincipe", this.newPrincipe).then(() => {
          this.submitingForm = false;
        });
      }
    },
  },
  computed: {
    ...mapGetters("principe", ["PRINCIPE"]),
  },
  watch: {
    PRINCIPE() {
      this.submitingForm = false;

      Swal.fire({
        title: "Le principe est bien créé !",
        type: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "show-principe",
            params: { slug: this.PRINCIPE.slug, id: this.PRINCIPE.id,
            fromCreation : true },
          });
        }
      });
    },
  },
};
</script>
